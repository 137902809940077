import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const refHighestListTable = ref(null)
  const refLowestListTable = ref(null)
  const refPopularListTable = ref(null)

  const perPage = ref(30)

  const totalItems = ref(0)
  const currentPage = ref(1)

  const totalHighestItems = ref(0)
  const currentHighestPage = ref(1)

  const totalLowestItems = ref(0)
  const currentLowestPage = ref(1)

  const totalPopularItems = ref(0)
  const currentPopularPage = ref(1)

  const perPageOptions = [15, 30, 60, 90]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const dataHighestMeta = computed(() => {
    const localItemsCount = refHighestListTable.value ? refHighestListTable.value.localItems.length : 0
    return {
      from: (perPage.value / 3) * (currentHighestPage.value - 1) + (localItemsCount ? 1 : 0),
      to: (perPage.value / 3) * (currentHighestPage.value - 1) + localItemsCount,
      of: totalHighestItems.value,
    }
  })

  const dataLowestMeta = computed(() => {
    const localItemsCount = refLowestListTable.value ? refLowestListTable.value.localItems.length : 0
    return {
      from: (perPage.value / 3) * (currentLowestPage.value - 1) + (localItemsCount ? 1 : 0),
      to: (perPage.value / 3) * (currentLowestPage.value - 1) + localItemsCount,
      of: totalLowestItems.value,
    }
  })

  const dataPopularMeta = computed(() => {
    const localItemsCount = refPopularListTable.value ? refPopularListTable.value.localItems.length : 0
    return {
      from: (perPage.value / 3) * (currentPopularPage.value - 1) + (localItemsCount ? 1 : 0),
      to: (perPage.value / 3) * (currentPopularPage.value - 1) + localItemsCount,
      of: totalPopularItems.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  const refetchHighestData = () => {
    refHighestListTable.value.refresh()
  }

  const refetchLowestData = () => {
    refLowestListTable.value.refresh()
  }

  const refetchPopularData = () => {
    refPopularListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  watch([currentHighestPage, perPage, searchQuery], () => {
    refetchHighestData()
  })

  watch([currentLowestPage, perPage, searchQuery], () => {
    refetchLowestData()
  })

  watch([currentPopularPage, perPage, searchQuery], () => {
    refetchPopularData()
  })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('search_history/paginate', {
        query: searchQuery.value,
        per_page: perPage.value,
        page: currentPage.value,
        sort_by: sortBy.value,
        sort_desc: isSortDirDesc.value,
      })
      .then(response => {
        const { data, meta: { total } } = response.data

        callback(data)
        totalItems.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchHighestData = (ctx, callback) => {
    store
      .dispatch('search_history/highest', {
        query: searchQuery.value,
        per_page: perPage.value / 3,
        page: currentHighestPage.value,
      })
      .then(response => {
        const { data, meta: { total } } = response.data

        callback(data)
        totalHighestItems.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchLowestData = (ctx, callback) => {
    store
      .dispatch('search_history/lowest', {
        query: searchQuery.value,
        per_page: perPage.value / 3,
        page: currentLowestPage.value,
      })
      .then(response => {
        const { data, meta: { total } } = response.data

        callback(data)
        totalLowestItems.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchPopularData = (ctx, callback) => {
    store
      .dispatch('search_history/popular', {
        query: searchQuery.value,
        per_page: perPage.value / 3,
        page: currentPopularPage.value,
      })
      .then(response => {
        const { data, meta: { total } } = response.data

        callback(data)
        totalPopularItems.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchData,
    fetchHighestData,
    fetchLowestData,
    fetchPopularData,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    currentHighestPage,
    totalHighestItems,
    dataHighestMeta,
    currentLowestPage,
    totalLowestItems,
    dataLowestMeta,
    currentPopularPage,
    totalPopularItems,
    dataPopularMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refHighestListTable,
    refLowestListTable,
    refPopularListTable,

    refetchData,
    refetchHighestData,
    refetchLowestData,
    refetchPopularData,
  }
}
