<template>
  <BRow>
    <BCol cols="12">
      <b-card
        no-body
        class="mb-2"
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('administration.table.settings.show') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ $t('administration.table.settings.entries') }}</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('administration.table.settings.search') + '...'"
                />
                <b-button
                  variant="warning"
                  :disabled="exportWait"
                  @click="exportReport"
                >
                  <span class="text-nowrap">Export</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
      </b-card>
    </BCol>
    <BCol
      cols="12"
      md="4"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="card-header">
          <b-card-sub-title v-text="$t('form.search.highest_desc')" />
        </div>
        <b-table
          ref="refHighestListTable"
          class="position-relative"
          :items="fetchHighestData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('administration.table.settings.empty_text')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(query)="data">
            <div v-if="data.item.query !== data.item.relation">
              <span>{{ data.item.query }} -> </span>
              <b> {{ data.item.relation }}</b>
            </div>
            <b v-else>{{ data.item.query }}</b>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('administration.table.settings.showing') }} {{ dataHighestMeta.from }} {{ $t('administration.table.settings.to') }} {{ dataHighestMeta.to }} {{ $t('administration.table.settings.of') }} {{ dataHighestMeta.of }} {{ $t('administration.table.settings.entries') }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentHighestPage"
                :total-rows="totalHighestItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </BCol>
    <BCol
      cols="12"
      md="4"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="card-header">
          <b-card-sub-title v-text="$t('form.search.lowest_desc')" />
        </div>
        <b-table
          ref="refLowestListTable"
          class="position-relative"
          :items="fetchLowestData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('administration.table.settings.empty_text')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(query)="data">
            <div v-if="data.item.query !== data.item.relation">
              <span>{{ data.item.query }} -> </span>
              <b> {{ data.item.relation }}</b>
            </div>
            <b v-else>{{ data.item.query }}</b>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('administration.table.settings.showing') }} {{ dataLowestMeta.from }} {{ $t('administration.table.settings.to') }} {{ dataLowestMeta.to }} {{ $t('administration.table.settings.of') }} {{ dataLowestMeta.of }} {{ $t('administration.table.settings.entries') }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentLowestPage"
                :total-rows="totalLowestItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </BCol>
    <BCol
      cols="12"
      md="4"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="card-header">
          <b-card-sub-title v-text="$t('form.search.popular_desc')" />
        </div>
        <b-table
          ref="refPopularListTable"
          class="position-relative"
          :items="fetchPopularData"
          responsive
          :fields="tableColumnsPopular"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('administration.table.settings.empty_text')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(query)="data">
            <div v-if="data.item.query !== data.item.relation">
              <span>{{ data.item.query }} -> </span>
              <b> {{ data.item.relation }}</b>
            </div>
            <b v-else>{{ data.item.query }}</b>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('administration.table.settings.showing') }} {{ dataPopularMeta.from }} {{ $t('administration.table.settings.to') }} {{ dataPopularMeta.to }} {{ $t('administration.table.settings.of') }} {{ dataPopularMeta.of }} {{ $t('administration.table.settings.entries') }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPopularPage"
                :total-rows="totalPopularItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </BCol>
    <BCol cols="12">
      <b-card
        no-body
        class="mb-2"
      >
        <div class="card-header">
          <b-card-sub-title v-text="$t('form.search.history_desc')" />
        </div>
        <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumnsHistory"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('administration.table.settings.empty_text')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(query)="data">
            <div v-if="data.item.query !== data.item.relation">
              <span>{{ data.item.query }} -> </span>
              <b> {{ data.item.relation }}</b>
            </div>
            <b v-else>{{ data.item.query }}</b>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('administration.table.settings.showing') }} {{ dataMeta.from }} {{ $t('administration.table.settings.to') }} {{ dataMeta.to }} {{ $t('administration.table.settings.of') }} {{ dataMeta.of }} {{ $t('administration.table.settings.entries') }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </BCol>
  </BRow>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BCardSubTitle, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useList from './useList'
import storeModule from './storeModule'

export default {
  components: {
    BButton,
    vSelect,
    BCardSubTitle,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
  },
  data() {
    return {
      tableColumns: [
        { key: 'query', label: this.$t('administration.table.fields.query'), sortable: false },
        { key: 'results', label: this.$t('administration.table.fields.results'), sortable: false },
      ],
      tableColumnsPopular: [
        { key: 'query', label: this.$t('administration.table.fields.query'), sortable: false },
        { key: 'results', label: this.$t('administration.table.fields.counts'), sortable: false },
      ],
      tableColumnsHistory: [
        { key: 'query', label: this.$t('administration.table.fields.query'), sortable: false },
        { key: 'results', label: this.$t('administration.table.fields.results'), sortable: false },
        { key: 'created_at', label: this.$t('administration.table.fields.created_at'), sortable: false },
      ],
      exportWait: false,
    }
  },
  methods: {
    exportReport() {
      this.exportWait = true
      this.$http.get('/api/search/history/export')
        .then(response => {
          window.open(response.data.url, '_blank')
          this.exportWait = false
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.exportWait = false
        })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'search_history'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      fetchHighestData,
      fetchLowestData,
      fetchPopularData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      currentHighestPage,
      totalHighestItems,
      dataHighestMeta,
      currentLowestPage,
      totalLowestItems,
      dataLowestMeta,
      currentPopularPage,
      totalPopularItems,
      dataPopularMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refHighestListTable,
      refLowestListTable,
      refPopularListTable,

      refetchData,
      refetchHighestData,
      refetchLowestData,
      refetchPopularData,
    } = useList()

    return {
      fetchData,
      fetchHighestData,
      fetchLowestData,
      fetchPopularData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      currentHighestPage,
      totalHighestItems,
      dataHighestMeta,
      currentLowestPage,
      totalLowestItems,
      dataLowestMeta,
      currentPopularPage,
      totalPopularItems,
      dataPopularMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refHighestListTable,
      refLowestListTable,
      refPopularListTable,

      refetchData,
      refetchHighestData,
      refetchLowestData,
      refetchPopularData,
    }
  },
}
</script>
